document.addEventListener('DOMContentLoaded', () => {

    /* Checked input and actions label  */

    const checkedLabelAction = () => {
        const inputElements = document.querySelectorAll('.js-input-action');

        if (inputElements) {
            inputElements.forEach(el => {

                el.addEventListener('blur', function () {
                    document.querySelectorAll('.js-label-action').forEach(item => {
                        item.classList.remove('focus-active');
                    })
                });

                el.addEventListener('focus', function () {
                    el.closest('.js-label-action').classList.add('focus-active');
                });

                el.addEventListener('change', e => {
                    const input = e.target;
                    if (input.value.length > 0) {
                        input.closest('.js-label-action').classList.add('input-not-empty');
                    } else {
                        input.closest('.js-label-action').classList.remove('input-not-empty');
                    }
                });
            });
        }
    }

    /* Add hidden topic to carier */

    const addTopicValue = () => {
        const topicInputs = document.querySelectorAll('.career-topic-js');

        if (topicInputs) {
            topicInputs.forEach(el => {
                let parentForm = el.closest('.c-form-basic');
                if (parentForm) {
                    let dataTopic = parentForm.getAttribute('data-topic');
                    if (dataTopic) el.value = dataTopic;
                }
            });
        }
    }


    checkedLabelAction();
    addTopicValue();

});